import Guacamole from "guacamole-common-js";
import React from "react";

import { CircularProgress, Typography } from "@material-ui/core/";

import encrypt from "./encrypt.js";

class GuacamoleStage extends React.Component {
  constructor(props) {
    super(props);
    let user_id = window.location.href.split("?")[1];
    this.myRef = React.createRef();

    this.token = encrypt({
      connection: {
        type: "rdp",
        settings: {
          user_id,
          "enable-drive": true,
          "create-drive-path": true,
          security: "rdp",
          "ignore-cert": true,
          "enable-wallpaper": false,
          width: window.innerWidth,
          height: window.innerHeight,
        },
      },
    });
    const url = "wss://bidbox.awes.com.au/api";

    this.tunnel = new Guacamole.WebSocketTunnel(url);
    this.client = new Guacamole.Client(this.tunnel);

    this.state = {
      connectingMessage: "Connecting",
    };
  }

  componentDidMount() {
    this.myRef.current.appendChild(this.client.getDisplay().getElement());

    this.client.onerror = (error) => {
      console.log(error.message);
      alert(error.message);
      this.setState({ connectingMessage: "Connection failed" });
    };

    this.tunnel.onerror = (error) => {
      console.log(error.message);
      alert(error.message);
      this.setState({ connectingMessage: "Connection failed" });
    };

    this.tunnel.onstatechange = (res) => {
      if (res === (1 || 2)) {
        this.setState({ connectingMessage: false });
      }
    };

    this.client.connect("token=" + this.token);

    this.mouse = new Guacamole.Mouse(this.client.getDisplay().getElement());
    this.mouse.onmousedown = this.mouse.onmouseup = this.mouse.onmousemove = (
      mouseState
    ) => {
      this.client.sendMouseState(mouseState);
    };

    this.keyboard = new Guacamole.Keyboard(document);
    this.keyboard.onkeydown = (keysym) => {
      this.client.sendKeyEvent(1, keysym);
    };

    this.keyboard.onkeyup = (keysym) => {
      this.client.sendKeyEvent(0, keysym);
    };

    window.onunload = () => {
      this.client.disconnect();
    };
  }

  render() {
    const { connectingMessage } = this.state;

    return (
      <div style={{ cursor: "none" }} ref={this.myRef}>
        {connectingMessage && (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {connectingMessage === "Connecting" && <CircularProgress />}
            <Typography variant="h5" component="p">
              {connectingMessage}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

export default GuacamoleStage;
